export default (canvas) => {
    window.addEventListener('dblclick', () => {

        const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement;

        if(!fullscreenElement){
            if(canvas.requestFullscreen){
                canvas.requestFullscreen();
            }else if(canvas.webkitFullscreenElement){
                canvas.webkitFullscreenElement();
            }
        }else{
            if(document.exitFullscreen){
                document.exitFullscreen();
            }else if(document.webkitExitFullscreen){
                document.webkitExitFullscreen();
            }
        }
    })
}