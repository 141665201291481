import * as THREE from 'three';
import resizeHandle from './components/resizeHandle.js';
import doubleClickHandle from './components/doubleClickHandle.js';
import mouseMoveHandle from './components/mouseMoveHandle.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import addEnvironment from './components/addEnvironment.js';
import sizes from './contants/sizes.js';
import Gui from 'lil-gui';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';

// const gui = new Gui();
const canvas = document.querySelector('canvas.webgl');
const renderer = new THREE.WebGLRenderer({ canvas: canvas })
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 1, 1000);
camera.position.z = 12;
camera.position.x = 0;
camera.position.y = 0;
const controller = new OrbitControls(camera, canvas);
controller.autoRotate = true;
controller.autoRotateSpeed = 1;
controller.maxDistance = 20;
controller.minDistance = 5;
const clock = new THREE.Clock();

// Textures
// const textureLoader = new THREE.TextureLoader();
// const doorColorTexture = textureLoader.load('./textures/door/basecolor.jpg');
// const doorAlphaTexture = textureLoader.load('./textures/door/opacity.jpg');
// const doorAmbientOcclusionTexture = textureLoader.load('./textures/door/ambientOcclusion.jpg');
// const doorHeightTexture = textureLoader.load('./textures/door/height.png');
// const doorNormalTexture = textureLoader.load('./textures/door/normal.jpg');
// const doorMetalnessTexture = textureLoader.load('./textures/door/metallic.jpg');
// const doorRoughnessTexture = textureLoader.load('./textures/door/roughness.jpg');
// doorColorTexture.colorSpace = THREE.SRGBColorSpace;

// Material
// const material = new THREE.MeshStandardMaterial({
//     side: THREE.DoubleSide,
//     metalness: 1,
//     roughness: 1,
//     map: doorColorTexture,
//     aoMap: doorAmbientOcclusionTexture,
//     aoMapIntensity: 1,
//     displacementMap: doorHeightTexture,
//     displacementScale: 5,
//     metalnessMap: doorMetalnessTexture,
//     roughnessMap: doorRoughnessTexture,
//     normalMap: doorNormalTexture,
//     normalScale: {
//         x: .5,
//         y: .5
//     },
//     transparent: true,
//     alphaMap: doorAlphaTexture
// });

// Plane
// const planeGeometry = new THREE.PlaneGeometry(80, 80, 1000, 1000);
// const plane = new THREE.Mesh(planeGeometry, material);
// plane.rotation.set(Math.PI, Math.PI / 2, 0);

// Sphere
// const sphereGeometry = new THREE.SphereGeometry(50, 1600, 1600);
// const sphere = new THREE.Mesh(sphereGeometry, material);
// sphere.position.set(0, 0, 100);

// Donut
// const donutGeometry = new THREE.TorusGeometry(30, 20, 1600, 3200)
// const donut = new THREE.Mesh(donutGeometry, material);
// donut.position.set(0, 0, -120);
// donut.rotation.set(0, Math.PI / 2, 0);
// const meshesGui = gui.addFolder('Meshes');
// meshesGui.add(material, 'metalness').min(0).max(1).name('Metalness').step(.01);
// meshesGui.add(material, 'roughness').min(0).max(1).name('Roughness').step(.01);
// meshesGui.add(material, 'aoMapIntensity').min(0).max(1).name('AO Map Intensity').step(.01);
// meshesGui.add(material, 'displacementScale').min(0).max(10).name('Displacement Scale').step(.1);
// meshesGui.add(material.normalScale, 'x').min(0).max(1).name('Normal Scale X').step(.1);
// meshesGui.add(material.normalScale, 'y').min(0).max(1).name('Normal Scale Y').step(.1);

// Font
const fontLoader = new FontLoader();
fontLoader.load(
    './fonts/Quitars_Regular.json',
    (font) => {
        const textGeometry = new TextGeometry(
            'Nous mettons à jour \nNotre site web...\nA bientot !',
            {
                font: font,
                size: 0.5,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5
            }
        )
        textGeometry.computeBoundingBox()
        textGeometry.translate(
            - textGeometry.boundingBox.max.x * 0.5,
            0,
            - textGeometry.boundingBox.max.z * 0.5
        )
        const textMaterial = new THREE.MeshNormalMaterial()
        const text = new THREE.Mesh(textGeometry, textMaterial)
        scene.add(text)
    }
);

// Particles
const parameters = {
    materialColor: '#ffeded'
}
const particlesCount = 200;
const positions = new Float32Array(particlesCount * 3);
for(let i = 0; i < particlesCount; i++) {
    positions[i * 3 + 0] = (Math.random() - 0.5) * 10;
    positions[i * 3 + 1] = (Math.random() - 0.5) * 10;
    positions[i * 3 + 2] = (Math.random() - 0.5) * 10;
}
const particlesGeometry = new THREE.BufferGeometry();
particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
const particlesMaterial = new THREE.PointsMaterial({
    color: parameters.materialColor,
    sizeAttenuation: true,
    size: 0.03
});
const particles = new THREE.Points(particlesGeometry, particlesMaterial);

// Animation refresh
(function tick () {
    const elapsedTime = clock.getElapsedTime();

    // sphere.rotation.y = 0.1 * elapsedTime;
    // plane.rotation.y = 0.1 * elapsedTime;
    // donut.rotation.y = 0.1 * elapsedTime;

    // sphere.rotation.x = 0.3 * elapsedTime;
    // plane.rotation.x = 0.3 * elapsedTime;
    // donut.rotation.x = 0.3 * elapsedTime;

    camera.lookAt(0,0);
    renderer.render(scene, camera);
    controller.update();
    
    window.requestAnimationFrame(tick);
})();

// addEnvironment(scene);

mouseMoveHandle();

resizeHandle(camera, renderer);

doubleClickHandle();

scene.add(camera, particles);